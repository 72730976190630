import './App.css';
import logo from './assets/images/logo.png';
import ServiceList from './components/service/ServiceList';
import Instagram from './components/instagram/Instagram';
import ReviewList from './components/review/ReviewList';
import Contact from './components/contact/Contact';
import OpeningHour from './components/opening-hour/OpeningHour';
import Footer from './components/footer/Footer';

import WebDecorationLeft from './assets/images/web-deoration-left.png';
import WebDecorationRight from './assets/images/web-decoration-right.png';

function App() {
  return (
    <div className="app">
        <img className='logo' src={logo} alt='moonnila logo' />
        <ServiceList />
        <div className='web-decoration'>
          <div className='web-decoration-left'>
            <img className='web-decoration-img' src={WebDecorationLeft} alt='decoration-left' />
            <img className='web-decoration-img' src={WebDecorationRight} alt='decoration-left2' />
          </div>
          <div className='insta-review'>
            <Instagram />
            <ReviewList />
            <Contact />
          </div>
          <div className='web-decoration-right'>
            <img className='web-decoration-img' src={WebDecorationRight} alt='decoration-right' />
            <img className='web-decoration-img' src={WebDecorationLeft} alt='decoration-right2' />
          </div>
        </div>
        <OpeningHour />
        <Footer />
    </div>
  );
}

export default App;
