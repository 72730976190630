import './css/ServiceList.css';
import ServiceCard from "./ServiceCard";
import plainColour from '../../assets/images/services/plain-colour.png';
import multipleColour from '../../assets/images/services/multiple-colour.jpeg';
import ombre from '../../assets/images/services/ombre.png';
import frenchTips from '../../assets/images/services/french-tips.png';
import basicCustomDesign from '../../assets/images/services/basic-custom-design.jpeg';
import { Grid } from "@mui/material";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

const ServiceList = () => {

    const serviceList = [
        { image: plainColour, title: 'Plain colour', time: '1h 30min', description: 'Dieses Set enthält nur eine Farbe', price: '30.-' },
        { image: multipleColour, title: 'Multiple colour', time: '1h 40min', description: 'Dieses Set besteht aus zwei unterschiedlichen uni Farben', price: '35.-' },
        { image: ombre, title: 'Ombre', time: '2h', description: 'Dieses Set ist eine Kombination aus zwei Farben', price: '40.-' },
        { image: frenchTips, title: 'French tips', time: '2h 30min', description: 'Ein Set French Tip design in einer Farbe Ihrer Wahl', price: '45.-' },
        { image: basicCustomDesign, title: 'Custom design', time: '3h', description: 'Dies ist ein komplettes Set mit sehr einfacher Nailart', price: '50.-' },
    ]

    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.only('xs'));

    let itemsPerRow;
    if (isXs) {
        itemsPerRow = 1;
    } else {
        itemsPerRow = 2;
    }

    const calculateIsLastItemAlone = (totalItems, itemsPerRow) => {
        return totalItems % itemsPerRow === 1;
    };
      
    const isLastItemAlone = calculateIsLastItemAlone(serviceList.length, itemsPerRow);

    return (
        <div className='service-list'>
            <Grid container spacing={2}>
                {serviceList.map((service, index) => (
                    <Grid item xs={12} xl={6} key={index} style={isLastItemAlone && index === serviceList.length - 1 ? { margin: '0 auto' } : {}}>
                        <ServiceCard
                            image={service.image}
                            title={service.title}
                            time={service.time}
                            price={service.price}
                            description={service.description}
                            delay={index * 0.2}
                        />
                    </Grid>
                ))}
            </Grid>
        </div>
    )
}

export default ServiceList;