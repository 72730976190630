import './OpeningHour.css';
import { Typography } from "@mui/material";

const OpeningHour = () => {

    const openingHours = [
        { day: 'Montag', time: 'Ruhetag' },
        { day: 'Dienstag', time: 'Ruhetag' },
        { day: 'Mittwoch', time: '18:30 - 22:00' },
        { day: 'Donnerstag', time: '18:30 - 22:00' },
        { day: 'Freitag', time: '18:30 - 22:00' },
        { day: 'Samstag', time: '09:00 - 11:30' },
        { day: 'Sonntag', time: '09:00 - 15:45' },
    ]

    return (
        <div className='opening-hour'>
            <div className='opening-hour-values'>
                <Typography variant='h5'>Öffnungszeiten</Typography>
                { openingHours.map((openingHour, index) => (
                    <div className='opening-time' key={index}>
                        <Typography className='opening-text'>{openingHour.day}</Typography>
                        <Typography className='opening-text'>{openingHour.time}</Typography>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default OpeningHour;