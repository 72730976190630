import './Footer.css';
import { Link } from "@mui/material";

const Footer = () => {

    return(
        <div className='footer'>
            <Link style={{textDecoration: 'none', color: 'black'}}
                hrfe='https://www.polyapps.ch'
                sx={{
                    fontSize: {
                        xs: '0.8rem',
                        sm: '1rem',
                        md: '1.2rem',
                    },
                    marginLeft: '1rem',
                    cursor: 'pointer',
                }}>©2024 PolyApps</Link>
            <div className='legal'>
            <Link style={{textDecoration: 'underline', color: 'black'}}
                hrfe='https://www.polyapps.ch'
                sx={{
                    fontSize: {
                        xs: '0.8rem',
                        sm: '1rem',
                        md: '1.2rem',
                    },
                    marginLeft: '1rem',
                    cursor: 'pointer',
                }}>AGB</Link>
            <Link style={{textDecoration: 'underline', color: 'black'}}
                hrfe='https://www.polyapps.ch'
                sx={{
                    fontSize: {
                        xs: '0.8rem',
                        sm: '1rem',
                        md: '1.2rem',
                    },
                    marginLeft: '1rem',
                    cursor: 'pointer',
                }}>Datenschuterklärung</Link>
            </div>
        </div>
    );

}

export default Footer;