import './css/Review.css';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import stars from '../../assets/images/stars.png';

const Review = ({ reviewText, reviewer }) => {

    return (
        <Grid className='review' container>
            <Grid xs={12}>
                <Typography sx={{
                    textAlign: 'center',
                    fontFamily: 'Playfair Display, serif',
                    fontSize: {
                        xs: '1rem',
                        sm: '1.2rem',
                        md: '1.5rem',
                    }
                }}>{reviewText}</Typography>
            </Grid>
            <Grid xs={6}>
                <img src={stars} alt='rating-stars' style={{ maxWidth: '100%', height: 'auto' }} />
            </Grid>
            <Grid xs={6}>
                <Typography 
                    sx={{
                        fontFamily: 'Playfair Display, serif',
                        textAlign: 'right',
                        fontSize: {
                            xs: '0.8rem',
                            sm: '1rem',
                            md: '1.2rem',
                        }
                    }}>
                {reviewer}</Typography>
            </Grid>
        </Grid>
    )
}

export default Review;