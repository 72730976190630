import './css/ServiceCard.css';
import { Card, CardContent, Typography } from "@mui/material"

const ServiceCard = ({ image, title, time, description, price, delay }) => {
    
    return (
        <Card className='card' style={{ borderRadius: '30px', animation: `fadeInUp 0.8s ease-out forwards ${delay}s`, opacity: 0 }}>
            <CardContent className='service-card'>
                <img src={image} alt='plain colour service' />
                <div className='service-infos'>
                    <div>
                        <Typography sx={{
                            fontSize: {
                                xs: '1rem',
                                sm: '1.4rem',
                                md: '1.8rem',
                            },
                            letterSpacing: '2px',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis'
                        }}>{title}</Typography>
                        <Typography sx={{
                            fontSize: {
                                xs: '0.8rem',
                                sm: '1rem',
                                md: '1.2rem',
                            },
                            letterSpacing: '2px',
                            display: '-webkit-box',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            WebkitBoxOrient: 'vertical',
                            WebkitLineClamp: {
                                xs: 3,
                                sm: 3,
                            },
                            minHeight: {
                                sm: '50px'
                            },
                            hyphens: 'auto',
                            language: 'de-CH',
                        }}>{description}</Typography>
                    </div>
                    <div style={{alignItems: 'center', display: 'flex', justifyContent: 'space-between'}}>
                        <Typography sx={{
                            fontSize: {
                                xs: '1rem',
                                sm: '1.2rem',
                                md: '1.5rem'
                            },
                            fontFamily: 'Playfair Display, serif',
                            letterSpacing: '2px',
                        }}>{time}</Typography>
                        <Typography style={{
                            fontSize: '1rem',
                            fontWeight: 'bold',
                            fontFamily: 'Playfair Display, serif',
                            letterSpacing: '2px',
                            backgroundColor: '#FEB1B1',
                            borderRadius: '15px',
                            paddingTop: '0.3rem',
                            paddingBottom: '0.5rem',
                            paddingLeft: '0.5rem',
                            paddingRight: '0.5rem',
                        }}>{price}</Typography>
                    </div>
                </div>
            </CardContent>
        </Card>
    )
}

export default ServiceCard;