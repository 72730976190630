import './css/Instagram.css';
import { Link, Typography } from '@mui/material';
import instagram from '../../assets/images/instagram.png';

const Instagram = () => {

    return (
        <div className='instagram'>
            <div className='insta-left-side' onClick={() => window.location.href='https://www.instagram.com/_.moonnila._/?next=%252F'}>
                  <img className='insta-logo' src={instagram} alt='instagram-icon' />
                  <Link className='insta-link' color='#FBB1B1' href='https://www.instagram.com/_.moonnila._/?next=%252F'
                      sx={{
                        fontSize: {
                          xs: '1.2rem',
                          sm: '1.5rem',
                          md: '1.8rem',
                        },
                        fontWeight: 'bold',
                      }}>@_.moonnila._</Link>
              </div>
              <div className='insta-right-side'>
                  <Typography className='follow-text' sx={{
                        fontSize: {
                          xs: '0.8rem',
                          sm: '1.2rem',
                          md: '1.5rem',
                        },
                        fontFamily: 'Playfair Display, serif'}}>Folge <b>Moonnila</b> auf Instagram und entdecke unendliche Inspiration für atemberaubende Nageldesigns</Typography>
              </div>
          </div>
    )
}

export default Instagram;