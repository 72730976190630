import './Contact.css';
import location from '../../assets/images/location.png';
import contact from '../../assets/images/email.png';
import { Typography } from '@mui/material';

const Contact = () => {
    return (
        <div className='contact'>
            <div className='info'>
                <img src={location} alt='location-icon' width='50px' onClick={() => window.location.href='https://www.google.com/maps/search/?api=1&query=Mumenthalstrasse+35,+Aarwangen,+CH,+4912'} />
                <Typography className='contact-text' onClick={() => window.location.href='https://www.google.com/maps/search/?api=1&query=Mumenthalstrasse+35,+Aarwangen,+CH,+4912'}>Mumenthalstrasse 35, Aarwangen 4912</Typography>
            </div>
            <div className='info'>
                <img src={contact} alt='contact-icon' width='50px' onClick={() => window.location.href='mailto:info@moonnila.ch'}/>
                <Typography className='contact-text' onClick={() => window.location.href='mailto:info@moonnila.ch'}>info@moonnila.ch</Typography>
            </div>
        </div>
    );
}

export default Contact;