import './css/ReviewList.css';
import Review from './Review';
import { useTransition, animated } from 'react-spring';
import { useEffect, useState } from 'react';

const ReviewList = () => {

    const [reviews] = useState([
        { 
            id: 1,
            reviewText: '“Ich liebe deine Arbeit. Du lässt dir immer Zeit und übertriffst jedesmal meine Erwartungen.”',
            reviewer: 'Januya'
        },
        { 
            id: 2,
            reviewText: '“Die Qualität der Nägel war überraschend gut und hat unerwartet lange gehalten.”',
            reviewer: 'Sayaa'
        },
        { 
            id: 3,
            reviewText: '“Du lässt dir viel Zeit und arbeites sauber und genau. Ich bin mit den Ergebnissen immer zufrieden.”',
            reviewer: 'Nethra Nallanathan'
        },
        { 
            id: 4,
            reviewText: '“Du arbeitest sehr sorgfältig und genau. Ich freue mich jedes Mal auf das Ergebnis.”',
            reviewer: 'Susiiee'
        }
    ]);

    const [currentIndex, setCurrentIndex] = useState(0);

    const fadingReviewPropsTransition = useTransition(reviews[currentIndex], {
        key: (review) => review.id,
        from: { opacity: 0, transform: 'translate3d(100%, 0, 0)' }, // Start from the right
        enter: { opacity: 1, transform: 'translate3d(0%, 0, 0)' }, // Move to original position
        leave: { opacity: 0, transform: 'translate3d(-100%, 0, 0)' },
        config: { tension: 220, friction: 120 },
      });
      

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((state) => (state + 1) % reviews.length);
        }, 5000);

        return () => clearInterval(interval);
    }, [currentIndex, reviews.length] );

    return (
        <div className='review-container'>
            <div className='review-list'>
                {fadingReviewPropsTransition((style, review) => (
                    <animated.div key={review.id} style={{ ...style, position: 'absolute'}}>
                        <Review reviewText={review.reviewText} reviewer={review.reviewer} />
                    </animated.div>
                ))} 
            </div>
        </div>

    )
}

export default ReviewList;